import { Box, Typography, styled } from "@mui/material";
import * as React from "react";
import axios from "axios";
import { Loader } from "../../components/Loader/Loader";

interface Props {
  banner: string;
  setBanner: React.Dispatch<React.SetStateAction<string>>;
}

const BioText = styled(Typography)(({ theme }) => ({
  // textAlign: "center",
}));

export const About: React.FC<Props> = ({ banner, setBanner }) => {
  const [bioOne, setBioOne] = React.useState("");
  const [bioTwo, setBioTwo] = React.useState("");
  const [bioThree, setBioThree] = React.useState("");
  const [bioFour, setBioFour] = React.useState("");
  const [bioFive, setBioFive] = React.useState("");
  const [bioSix, setBioSix] = React.useState("");
  const [imageV, setImageV] = React.useState("");
  const [imageP, setImageP] = React.useState("");
  const [imageA, setImageA] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get("https://headless.jonvickersmedia.com/wp-json/wp/v2/about")
      .then((res) => {
        setBanner(res.data[0].acf.banner);
        setBioOne(res.data[0].acf.text1);
        setBioTwo(res.data[0].acf.text2);
        setBioThree(res.data[0].acf.text3);
        setBioFour(res.data[0].acf.text4);
        setBioFive(res.data[0].acf.text5);
        setBioSix(res.data[0].acf.text6);
        setImageV(res.data[0].acf.vimage);
        setImageP(res.data[0].acf.pimage);
        setImageA(res.data[0].acf.aimage);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={(theme) => ({
            paddingTop: theme.palette.custom.xLarge,
            paddingLeft: theme.palette.custom.medium,
            paddingRight: theme.palette.custom.medium,
            paddingBottom: theme.palette.custom.xLarge,
            display: "flex",
            flexDirection: "column",
            rowGap: theme.palette.custom.xxLarge,
          })}
        >
          <Box
            sx={(theme) => ({
              display: "grid",
              justifyContent: "center",
              gridTemplateColumns: "1fr 2fr",
              columnGap: theme.palette.custom.xLarge,
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "1fr",
              },
            })}
          >
            <Box
              sx={(theme) => ({
                display: "flex",
                flexDirection: "column",
                rowGap: theme.palette.custom.xLarge,
              })}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography sx={{ fontSize: "2rem" }}>JON VICKERS</Typography>
              </Box>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  rowGap: theme.palette.custom.xLarge,
                })}
              >
                <BioText>{bioOne}</BioText>
                <BioText>{bioTwo}</BioText>
                {bioThree ? <BioText>{bioThree}</BioText> : null}
                {bioFour ? <BioText>{bioFour}</BioText> : null}
                {bioFive ? <BioText>{bioFive}</BioText> : null}
                {bioSix ? <BioText>{bioSix}</BioText> : null}
              </Box>
            </Box>
            <Box
              sx={(theme) => ({
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gridTemplateRows: "1fr 1fr",
                columnGap: theme.palette.custom.large,
                rowGap: theme.palette.custom.large,
                [theme.breakpoints.down("md")]: {
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: "1fr",
                  paddingBottom: theme.palette.custom.large,
                },
              })}
            >
              <Box
                sx={(theme) => ({
                  width: "100%",
                  gridRowStart: 1,
                  gridRowEnd: 3,
                  gridColumnStart: 2,
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                })}
              >
                <img
                  src={imageV}
                  alt="long"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box>
                <img
                  src={imageP}
                  alt="square"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
              <Box
                sx={(theme) => ({
                  [theme.breakpoints.down("md")]: {
                    display: "none",
                  },
                })}
              >
                <img
                  src={imageA}
                  alt="square"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};
