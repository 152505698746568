import * as React from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface Props {
  title: string;
  link: string;
  setMobileNav: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MobileLinkText: React.FC<Props> = ({
  title,
  link,
  setMobileNav,
}) => {
  const navigate = useNavigate();

  const click = (link: string) => {
    setMobileNav(false);
    navigate(link);
  };
  return (
    <Box onClick={() => click(link)}>
      <Typography
        sx={(theme) => ({
          padding: `${theme.palette.custom.small} 0px`,
          "&:hover": {
            cursor: "pointer",
            opacity: ".5",
          },
        })}
      >
        {title.toUpperCase()}
      </Typography>
    </Box>
  );
};
