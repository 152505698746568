import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Menu, MenuItem, Typography, styled } from "@mui/material";

interface Props {
  setMobileNav: React.Dispatch<React.SetStateAction<boolean>>;
  video: string[];
  mobileNav: boolean;
}

const LinkText = styled(Typography)(({ theme }) => ({
  padding: `${theme.palette.custom.small} 0px`,
  "&:hover": {
    cursor: "pointer",
    opacity: ".5",
  },
}));

export const VideoMenu: React.FC<Props> = ({
  setMobileNav,
  video,
  mobileNav,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (route: string) => {
    navigate(`/video/${route}`);
    setAnchorEl(null);
    setMobileNav(false);
  };

  const handleCloseOutside = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <LinkText
        sx={{ color: mobileNav ? "black" : "white" }}
        onClick={handleClick}
      >
        VIDEO
      </LinkText>
      <Menu open={open} anchorEl={anchorEl} onClose={handleCloseOutside}>
        {video.map((item, key) => (
          <MenuItem key={key} onClick={() => handleClose(item)}>
            {item.toUpperCase().replaceAll("-", " ")}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};
