import { Box, Typography } from "@mui/material";
import * as React from "react";
import { YoutubeEmbed } from "./YoutubeEmbed";
import axios from "axios";
import { Loader } from "../../components/Loader/Loader";
import { useLocation } from "react-router-dom";
import "./video.css";

interface Props {
  banner: string;
  setBanner: React.Dispatch<React.SetStateAction<string>>;
}

export const Video: React.FC<Props> = ({ setBanner, banner }) => {
  const [videos, setVideos] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();

  React.useEffect(() => {
    const currentLocation = location.pathname;
    let specificLocation = "";
    specificLocation = currentLocation.replace("/video", "").substring(1, 15);
    setLoading(true);
    axios
      .get(
        `https://headless.jonvickersmedia.com/wp-json/wp/v2/video-${specificLocation}`
      )
      .then((res) => {
        setBanner(res.data.reverse()[0].acf.banner);
        setVideos(res.data.reverse());
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [location.pathname, setBanner]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={(theme) => ({
            display: "grid",
            gridTemplateColumns: "1fr 2fr",
            gridTemplateRows: "fit-content fit-content",
            padding: `${theme.palette.custom.xLarge} ${theme.palette.custom.medium}`,
            rowGap: theme.palette.custom.xLarge,
            columnGap: theme.palette.custom.xLarge,
            [theme.breakpoints.down("md")]: {
              gridTemplateColumns: "1fr",
            },
          })}
        >
          {videos.map((item: any, key) => (
            <>
              <Box
                key={key}
                sx={(theme) => ({
                  display: "flex",
                  flexDirection: "column",
                  rowGap: theme.palette.custom.xLarge,
                  paddingTop: theme.palette.custom.xxLarge,
                })}
              >
                {item.acf.titleText ? (
                  <Typography sx={{ textAlign: "center" }} variant="h4">
                    {item.acf.titleText}
                  </Typography>
                ) : null}
                {item.acf.bodyText ? (
                  <Typography variant="h5">{item.acf.bodyText}</Typography>
                ) : null}
                {item.acf.bodyTextTwo ? (
                  <Typography variant="h5">{item.acf.bodyTextTwo}</Typography>
                ) : null}
                {item.acf.bodyTextThree ? (
                  <Typography variant="h5">{item.acf.bodyTextThree}</Typography>
                ) : null}
                {item.acf.bodyTextFour ? (
                  <Typography variant="h5">{item.acf.bodyTextFour}</Typography>
                ) : null}
                {item.acf.bodyTextFive ? (
                  <Typography variant="h5">{item.acf.bodyTextFive}</Typography>
                ) : null}
              </Box>
              <Box
                sx={(theme) => ({
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: theme.palette.custom.large,
                  gridAutoFlow: "dense",
                })}
              >
                <Box sx={{ gridColumn: "span 2" }}>
                  <YoutubeEmbed embedId={item.acf.embedID} />
                </Box>
                <>
                  {item.acf.imageOne ? (
                    item.acf.linkOne ? (
                      <a
                        href={item.acf.linkOne}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="imageLink"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                          src={item.acf.imageOne}
                          alt="First"
                        />
                      </a>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        src={item.acf.imageOne}
                        alt="First"
                      />
                    )
                  ) : null}
                </>
                <>
                  {item.acf.imageTwo ? (
                    item.acf.linkTwo ? (
                      <a
                        href={item.acf.linkTwo}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          className="imageLink"
                          style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "cover",
                          }}
                          src={item.acf.imageTwo}
                          alt="Second"
                        />
                      </a>
                    ) : (
                      <img
                        style={{
                          width: "100%",
                          height: "auto",
                          objectFit: "cover",
                        }}
                        src={item.acf.imageTwo}
                        alt="Second"
                      />
                    )
                  ) : null}
                </>
                {item.acf.embedIDTwo ? (
                  <Box sx={{ gridColumn: "span 2" }}>
                    <YoutubeEmbed embedId={item.acf.embedIDTwo} />
                  </Box>
                ) : null}
              </Box>
            </>
          ))}
        </Box>
      )}
    </>
  );
};
