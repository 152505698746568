import * as React from "react";
import { Box, Typography, styled } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Icons } from "./Icons/Icons";
import { PhotoMenu } from "./Menus/PhotoMenu";
import { VideoMenu } from "./Menus/VideoMenu";
import { useWindowDimensions } from "../../helpers/hooks/useWindowDimensions";
import { MobileNavbar } from "../MobileNavbar.tsx/MobileNavbar";
import "./Navbar.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

interface Props {
  mobileNav: boolean;
  setMobileNav: React.Dispatch<React.SetStateAction<boolean>>;
  photo: string[];
  video: string[];
  banner: string;
  loading: boolean;
  title: string;
}

const LinkText = styled(Typography)(({ theme }) => ({
  padding: `${theme.palette.custom.small} 0px`,
  color: "white",
  "&:hover": {
    cursor: "pointer",
    opacity: ".5",
  },
}));

export const Navbar: React.FC<Props> = ({
  mobileNav,
  setMobileNav,
  photo,
  video,
  banner,
  loading,
  title,
}) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const icon = document.getElementById("nav-icon4");

  React.useEffect(() => {}, [mobileNav]);
  if (mobileNav === true) {
    icon?.classList.add("open");
  } else {
    icon?.classList.remove("open");
  }

  return (
    <>
      {width <= 700 ? (
        <>
          <Box
            sx={(theme) => ({
              padding: theme.palette.custom.medium,
              display: "flex",
              justifyContent: "space-between",
              minWidth: "100vw",
              position: "absolute",
            })}
          >
            <div
              id="nav-icon4"
              onClick={() => setMobileNav(!mobileNav)}
              style={{ height: "10px" }}
            >
              <span></span>
              <span></span>
              <span></span>
            </div>
            <Icons />
          </Box>
          <MobileNavbar
            video={video}
            photo={photo}
            mobileNav={mobileNav}
            setMobileNav={setMobileNav}
          />
        </>
      ) : null}
      <Box
        sx={(theme) => ({
          background: "black",
          backgroundImage: loading ? "none" : `url(${banner})`,
          backgroundSize: width >= 500 ? "cover" : "cover",
          backgroundPosition: "50%, 0%",
          boxShadow: "0 4px 5px -2px black",
          display: "flex",
          flexDirection: "column",
          rowGap: "600px",
          paddingTop: theme.palette.custom.xxLarge,
          margin: "auto",
        })}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={(theme) => ({
              fontSize: "3rem",
              letterSpacing: "1.5rem",
              color: "white",
              textShadow: "3px 3px 5px black",
              "&:hover": {
                cursor: "pointer",
                opacity: ".5",
                [theme.breakpoints.down("md")]: {
                  opacity: "1",
                },
              },
              [theme.breakpoints.down("md")]: {
                fontSize: "2rem",
                paddingBottom: theme.palette.custom.xxLarge,
                cursor: "pointer",
                opacity: "1",
              },
            })}
            onClick={() => navigate("/")}
          >
            JON VICKERS MEDIA
          </Typography>
          {width <= 700 ? (
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: title.includes("full") ? "1.1rem" : "2rem",
                  letterSpacing: "1.2rem",
                  color: "white",
                })}
              >
                {title.toUpperCase().replaceAll("-", " ")}
              </Typography>
            </Box>
          ) : null}
        </Box>
        {width >= 700 ? (
          <Box
            sx={(theme) => ({
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            })}
          >
            <Box sx={{ textAlign: "center" }}>
              <Typography
                sx={(theme) => ({
                  fontSize: "3rem",
                  letterSpacing: "1.2rem",
                  color: "white",
                  textShadow: "3px 3px 5px black",
                })}
              >
                {title.toUpperCase().replaceAll("-", " ")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                columnGap: "40px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <LinkText onClick={() => navigate("/")}>HOME</LinkText>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  columnGap: theme.palette.custom.medium,
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <PhotoMenu
                  mobileNav={mobileNav}
                  photo={photo}
                  setMobileNav={setMobileNav}
                />
                <Box sx={{ marginTop: "4px" }}>
                  <a
                    style={{ color: "black" }}
                    href="https://www.instagram.com/jonvickersphoto/?hl=en"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <InstagramIcon
                      sx={{
                        color: "white",
                        "&:hover": {
                          cursor: "pointer",
                          opacity: ".5",
                        },
                      }}
                    />
                  </a>
                </Box>
              </Box>
              <Box
                sx={(theme) => ({
                  display: "flex",
                  columnGap: theme.palette.custom.medium,
                  justifyContent: "center",
                  alignItems: "center",
                })}
              >
                <VideoMenu
                  setMobileNav={setMobileNav}
                  video={video}
                  mobileNav={mobileNav}
                />
                <Box sx={{ marginTop: "4px" }}>
                  <a
                    style={{ color: "black" }}
                    href="https://www.youtube.com/@JVickers1000"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <YouTubeIcon
                      sx={{
                        color: "white",
                        "&:hover": {
                          cursor: "pointer",
                          opacity: ".5",
                        },
                      }}
                    />
                  </a>
                </Box>
              </Box>
              <LinkText onClick={() => navigate("/brands")}>BRANDS</LinkText>
              <LinkText onClick={() => navigate("/about")}>ABOUT</LinkText>
              <LinkText onClick={() => navigate("/contact")}>CONTACT</LinkText>
            </Box>
          </Box>
        ) : null}
      </Box>
    </>
  );
};
