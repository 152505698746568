import * as React from "react";
import { Box } from "@mui/material";
import { Loader } from "../../components/Loader/Loader";
import { Clients } from "../About/Clients/Clients";
import { Testimonials } from "../About/Testimonials/Testimonials";
import axios from "axios";

interface Props {
  banner: string;
  setBanner: React.Dispatch<React.SetStateAction<string>>;
}

export const Brands: React.FC<Props> = ({ banner, setBanner }) => {
  const [clients, setClients] = React.useState<string[]>([]);
  const [testimonials, setTestimonials] = React.useState<string[]>([]);
  const [authors, setAuthors] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    axios
      .get("https://headless.jonvickersmedia.com/wp-json/wp/v2/brands")
      .then((res) => {
        setBanner(res.data[0].acf.banner);
        setClients([
          ...clients,
          res.data[0].acf.logoOne,
          res.data[0].acf.logoTwo,
          res.data[0].acf.logoThree,
          res.data[0].acf.logoFour,
          res.data[0].acf.logoFive,
          res.data[0].acf.logoSix,
          res.data[0].acf.logoSeven,
          res.data[0].acf.logoEight,
        ]);

        setTestimonials([
          ...testimonials,
          res.data[0].acf.testimonialOne,
          res.data[0].acf.testimonialTwo,
          res.data[0].acf.testimonialThree,
          res.data[0].acf.testimonialFour,
        ]);
        setAuthors([
          ...authors,
          res.data[0].acf.authorOne,
          res.data[0].acf.authorTwo,
          res.data[0].acf.authorThree,
          res.data[0].acf.authorFour,
        ]);

        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            rowGap: "40px",
            padding: "40px 0px",
          })}
        >
          <Clients clients={clients} />
          <Testimonials authors={authors} testimonials={testimonials} />
        </Box>
      )}
    </>
  );
};
