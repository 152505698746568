import * as React from "react";
import { PhotographyMontage } from "../../components/PhotographyMontage/PhotographyMontage";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { Loader } from "../../components/Loader/Loader";

interface Props {
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  images: string[];
  images2?: string[];
  images3?: string[];
  loading: boolean;
}

export const Photography: React.FC<Props> = ({
  setImage,
  setModal,
  images,
  images2,
  images3,
  loading,
}) => {
  const location = useLocation();
  const currentLocation = location.pathname.replace("/photography/", "");

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            paddingTop: theme.palette.custom.large,
            justifyContent: "center",
            alignItems: "center",
          })}
        >
          <Box>
            {/* <Typography
              sx={(theme) => ({
                fontSize: "3rem",
                [theme.breakpoints.down("md")]: {
                  fontSize: "2rem",
                },
              })}
            >
              {currentLocation.toUpperCase().replaceAll("-", " ")}
            </Typography> */}
          </Box>
          <PhotographyMontage
            images={images}
            images2={images2}
            images3={images3}
            setImage={setImage}
            setModal={setModal}
            currentLocation={currentLocation}
          />
        </Box>
      )}
    </>
  );
};
