import * as React from "react";
import "./photo.css";
import { ImageOverlay } from "../ImageOverlay/ImageOverlay";
import { Box } from "@mui/material";

interface Props {
  currentLocation: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  images: string[];
  images2?: string[];
  images3?: string[];
}

export const PhotographyMontage: React.FC<Props> = ({
  setImage,
  setModal,
  images,
}) => {
  const getImage = (image: string) => {
    setImage(image);
    setModal(true);
  };

  return (
    <Box sx={(theme) => ({ padding: theme.palette.custom.medium })}>
      <Box className="gallery">
        {images!.slice(1).map((item: any, key) =>
          item.acf.caption ? (
            <ImageOverlay
              key={key}
              height={item.acf.height}
              width={item.acf.width}
              getImage={getImage}
              image={item.acf.image}
              caption={item.acf.caption}
              altText={item.acf.alt}
              className={
                item.acf.size === "horizontal"
                  ? "hStretch"
                  : item.acf.size === "vertical"
                  ? "vStretch"
                  : item.acf.size === "big"
                  ? "bigStretch"
                  : item.acf.size === "biggest"
                  ? "biggestStretch"
                  : ""
              }
            />
          ) : (
            <img
              key={key}
              // @ts-ignore
              onClick={(e) => getImage(e.target.getAttribute("src"))}
              src={item.acf.image}
              alt={item.acf.alt}
              style={{
                cursor: "pointer",
                gridRow: `span ${item.acf.height}`,
                gridColumn: `span ${item.acf.width}`,
              }}
            />
          )
        )}
      </Box>
    </Box>
  );
};
