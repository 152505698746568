import * as React from "react";
import { Box } from "@mui/material";
import "./Loader.css";

interface Props {}

export const Loader: React.FC<Props> = () => {
  return (
    <Box
      sx={(theme) => ({
        padding: `120px ${theme.palette.custom.medium}`,
        display: "flex",
        justifyContent: "center",
      })}
    >
      <span
        className="loader"
        style={{
          width: "48px",
          height: "48px",
          border: "5px solid black",
          borderBottomColor: "transparent",
          borderRadius: "50%",
          display: "inline-block",
          boxSizing: "border-box",
          animation: "rotation 1s linear infinite",
        }}
      ></span>
    </Box>
  );
};
