import * as React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { Photography } from "./Photography";
import axios from "axios";

interface Props {
  setImage: React.Dispatch<React.SetStateAction<string>>;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  images: string[];
  images2?: string[];
  images3?: string[];
  loading: boolean;
}

export const PhotographyNavigation: React.FC<Props> = ({
  setImage,
  setModal,
  images,
  images2,
  images3,
  loading,
}) => {
  const [routes, setRoutes] = React.useState<string[]>([]);

  React.useEffect(() => {
    axios
      .get(`https://jonvickersmedia.com/wp-json/wp/v2/photo-routes`)
      .then((res) => {
        setRoutes(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Box>
      <Routes>
        {routes.map((item: any, key) => (
          <Route
            key={key}
            path={`/${item.acf.route}`}
            element={
              <Photography
                loading={loading}
                images={images}
                images2={images2}
                images3={images3}
                setImage={setImage}
                setModal={setModal}
              />
            }
          />
        ))}
      </Routes>
    </Box>
  );
};
