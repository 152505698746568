import * as React from "react";
import { Box } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

interface Props {}

export const Icons: React.FC<Props> = () => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        columnGap: theme.palette.custom.medium,
      })}
    >
      <Box sx={(theme) => ({ padding: `${theme.palette.custom.small} 0px` })}>
        <a
          style={{ color: "white" }}
          href="https://www.instagram.com/jonvickersphoto/?hl=en"
          target="_blank"
          rel="noreferrer"
        >
          <InstagramIcon
            sx={(theme) => ({
              "&:hover": {
                cursor: "pointer",
                opacity: ".5",
                [theme.breakpoints.down("md")]: {
                  opacity: "1",
                },
              },
            })}
          />
        </a>
      </Box>
      <Box sx={(theme) => ({ padding: `${theme.palette.custom.small} 0px` })}>
        <a
          style={{ color: "white" }}
          href="https://www.youtube.com/@JVickers1000"
          target="_blank"
          rel="noreferrer"
        >
          <YouTubeIcon
            sx={(theme) => ({
              "&:hover": {
                cursor: "pointer",
                opacity: ".5",
                [theme.breakpoints.down("md")]: {
                  opacity: "1",
                },
              },
            })}
          />
        </a>
      </Box>
    </Box>
  );
};
