import * as React from "react";
import { Box } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { Video } from "./Video";
import axios from "axios";
import { VideoFull } from "./VideoFull/VideoFull";
import { VideoEye } from "./VideoEye/VideoEye";

interface Props {
  loading: boolean;
  banner: string;
  setBanner: React.Dispatch<React.SetStateAction<string>>;
}

export const VideoNavigation: React.FC<Props> = ({
  loading,
  setBanner,
  banner,
}) => {
  const [routes, setRoutes] = React.useState<string[]>([]);

  React.useEffect(() => {
    axios
      .get(`https://headless.jonvickersmedia.com/wp-json/wp/v2/routes-video`)
      .then((res) => {
        setRoutes(res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Box>
      <Routes>
        {routes.map((item: any, key) => (
          <Route
            key={key}
            path={`/${item.acf.route}`}
            element={<Video banner={banner} setBanner={setBanner} />}
          />
        ))}
      </Routes>
    </Box>
  );
};
