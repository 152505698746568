import * as React from "react";
import { Box, styled } from "@mui/material";
import { PhotoMenu } from "../Navbar.tsx/Menus/PhotoMenu";
import { MobileLinkText } from "./MobileLinkText/MobileLinkText";
import { VideoMenu } from "../Navbar.tsx/Menus/VideoMenu";

interface Props {
  mobileNav: boolean;
  setMobileNav: React.Dispatch<React.SetStateAction<boolean>>;
  photo: string[];
  video: string[];
}

const LinkBox = styled(Box)(({ theme }) => ({
  width: "max-content",
}));

export const MobileNavbar: React.FC<Props> = ({
  mobileNav,
  setMobileNav,
  photo,
  video,
}) => {
  React.useEffect(() => {
    if (mobileNav === true) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
  }, [mobileNav]);

  return (
    <Box
      sx={(theme) => ({
        width: "90vw",
        background: theme.palette.background.default,
        transition: ".5s",
        position: "fixed",
        top: "45px",
        left: mobileNav ? 0 : "-95vw",
      })}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          flexDirection: "column",
          rowGap: theme.palette.custom.xLarge,
          paddingLeft: theme.palette.custom.large,
          paddingBottom: theme.palette.custom.medium,
          paddingTop: theme.palette.custom.medium,
        })}
      >
        <MobileLinkText title="home" link="/" setMobileNav={setMobileNav} />
        <LinkBox>
          <PhotoMenu
            mobileNav={mobileNav}
            photo={photo}
            setMobileNav={setMobileNav}
          />
        </LinkBox>
        <VideoMenu
          setMobileNav={setMobileNav}
          mobileNav={mobileNav}
          video={video}
        />
        <MobileLinkText
          title="brands"
          link="/brands"
          setMobileNav={setMobileNav}
        />
        <MobileLinkText
          title="about"
          link="/about"
          setMobileNav={setMobileNav}
        />
        <MobileLinkText
          title="contact"
          link="/contact"
          setMobileNav={setMobileNav}
        />
      </Box>
    </Box>
  );
};
