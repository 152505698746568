import * as React from "react";
import classes from "./Test.module.scss";
import axios from "axios";

interface Props {}

export const Test: React.FC<Props> = () => {
  const [testData, setTestData] = React.useState("");
  const [testImage, setTestImage] = React.useState("");

  React.useEffect(() => {
    axios
      .get("https://jonvickersmedia.com/wp-json/wp/v2/test")
      .then((res) => {
        setTestData(res.data[0].acf.caption);
        setTestImage(res.data[0].acf.image);
        console.log("data", res.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div>
      <img src={testImage} alt="Not here" />
      <p>{testData}</p>
    </div>
  );
};
