import * as React from "react";
import { Box } from "@mui/material";
import { PhotographyMontage } from "../../components/PhotographyMontage/PhotographyMontage";
import { Loader } from "../../components/Loader/Loader";

interface Props {
  image: string;
  setImage: React.Dispatch<React.SetStateAction<string>>;
  modal: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
  images: string[];
  loading: boolean;
}

export const Home: React.FC<Props> = ({
  image,
  setImage,
  modal,
  setModal,
  images,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Box
          sx={(theme) => ({
            display: "flex",
            flexDirection: "column",
            rowGap: theme.palette.custom.large,
            paddingTop: theme.palette.custom.medium,
          })}
        >
          <PhotographyMontage
            images={images}
            currentLocation="home"
            setImage={setImage}
            setModal={setModal}
          />
        </Box>
      )}
    </>
  );
};
