import { createTheme, ThemeOptions } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";

// declare module "@mui/material/Typography" {
//   interface TypographyPropsVariantOverrides {
//     tBodyFade: true;
//     tBody: true;
//     bodyFade: true;
//     body: true;
//   }
// }

type Custom = {
  // negative: string;
  // positive: string;
  // lightRed: string;
  // marginals: string;
  // surface: string;
  // tableBorder: string;
  // sidebarBorder: string;
  // maxWidth: string;
  // textFade1: string;
  // textFade2: string;
  // textFade3: string;
  // textFade4: string;
  // warning: string;
  small: string;
  medium: string;
  large: string;
  xLarge: string;
  xxLarge: string;
};

type Backdrops = {
  dark: string;
  medium: string;
};
declare module "@mui/material/styles/createPalette" {
  interface Palette {
    custom: Custom;
    backdrops: Backdrops;
  }

  interface PaletteOptions {
    custom: Custom;
    backdrops: Backdrops;
  }
}
interface ExtendedTypographyOptions extends TypographyOptions {
  tBodyFade: React.CSSProperties;
  tBody: React.CSSProperties;
  bodyFade: React.CSSProperties;
  body: React.CSSProperties;
}

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
    text: {
      primary: "#000000",
      secondary: "#000000",
    },
    background: {
      default: "#FFFFFF",
    },
    custom: {
      // negative: "#DB3434",
      // positive: "#329D37",
      // lightRed: "#FD5151",
      // marginals: "#101014",
      // surface: "#22262E",
      // warning: "#FFB03A",
      // tableBorder: "#28313D",
      // sidebarBorder: "#262d38",
      // maxWidth: "1920px",
      // textFade1: "#5B667A",
      // textFade2: "#323C47",
      // textFade3: "#7DB9FF",
      // textFade4: "#A1B2CE",
      small: "6px",
      medium: "12px",
      large: "20px",
      xLarge: "40px",
      xxLarge: "80px",
    },
    backdrops: {
      dark: "#000000",
      medium: "#000000",
    },
  },
});

const font = "Sorts Mill Goudy";

export const jvTheme = createTheme(theme, {
  typography: {
    fontFamily: font,
    h1: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "40px",
    },
    body: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
    },
    bodyFade: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      color: theme.palette.text.secondary,
    },
    tBody: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      color: theme.palette.text.primary,
    },
    tBodyFade: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      color: theme.palette.text.secondary,
    },
    h4: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: "32px",
    },
    h5: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "32px",
    },
    h6: {
      fontFamily: font,
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "32px",
    },
  } as ExtendedTypographyOptions,
  components: {
    MuiTypography: {
      defaultProps: {
        fontFamily: font,
        variantMapping: {
          tBody: "p",
          tBodyFade: "p",
          body: "p",
          bodyFade: "p",
        },
      },
    },

    // MuiFilledInput: {
    //   defaultProps: {
    //     disableUnderline: true,
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     disableUnderline: true,
    //     background: "#000000",
    //   },
    // },
    MuiSelect: {
      styleOverrides: {
        root: {
          background: "#000000",
          color: "#000000",
        },
        notchedOutline: {
          borderWidth: "0",
        },
        icon: {
          color: "#000000",
        },
        select: {
          padding: "8px 16px",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          background: "#ffffff",
          maxHeight: "fit-content",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "0px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "1px solid #00000",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#000000",
          color: "#00000",
          borderRadius: "4px",
        },
      },
    },
  },
} as ThemeOptions);
