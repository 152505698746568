import * as React from "react";
import { Box, Typography } from "@mui/material";

interface Props {
  image: string;
  caption: string;
  className: string;
  altText: string;
  getImage: (image: string) => void;
  height: number;
  width: number;
}

export const ImageOverlay: React.FC<Props> = ({
  image,
  caption,
  className,
  altText,
  getImage,
  height,
  width,
}) => {
  const [hover, setHover] = React.useState(false);

  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className={className}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        transition: "transform .2s",
        gridRow: `span ${height}`,
        gridColumn: `span ${width}`,
        "&:hover": {
          transform: "scale(1.02)",
          cursor: "pointer",
        },
      }}
    >
      {hover ? (
        <Typography sx={{ position: "absolute", fontSize: "1.2rem" }}>
          {caption}
        </Typography>
      ) : null}
      <img
        // @ts-ignore
        onClick={(e) => getImage(e.target.getAttribute("src"))}
        src={image}
        alt={altText}
        className="overlayImage"
      />
    </Box>
  );
};
