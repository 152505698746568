import React from "react";
import "./App.css";
import { PagesNavigation } from "./pages/PagesNavigation";
// require("dotenv").config();

function App() {
  return <PagesNavigation />;
}

export default App;
